@keyframes motion-fade-enter {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes motion-fade-exit {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}