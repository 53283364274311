@keyframes motion-bounce-in-enter {
    0% {
        transform: translateY(-200px);
        animationTimingFunction: ease-in;
        opacity: 0;
    }

    38% {
        transform: translateY(0);
        animationTimingFunction: ease-out;
        opacity: 1;
    }

    55% {
        transform: translateY(-65px);
        animationTimingFunction: ease-in;
    }

    72% {
        transform: translateY(0);
        animationTimingFunction: ease-out;
    }

    81% {
        transform: translateY(-28px);
        animationTimingFunction: ease-in;
    }

    90% {
        transform: translateY(0);
        animationTimingFunction: ease-out;
    }

    95% {
        transform: translateY(-8px);
        animationTimingFunction: ease-in;
    }

    100% {
        transform: translateY(0);
        animationTimingFunction: ease-out;
    }
}