@keyframes motion-blur-enter {
    0% {
        filter: blur(2px);
    }

    100% {
        filter: blur(0px);
    }
}

@keyframes motion-blur-exit {
    0% {
        filter: blur(0px);
    }

    100% {
        filter: blur(2px);
    }
}