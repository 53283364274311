html[data-theme=light] {
	--bb-color-Primary: #444444;
	--bb-color-Secondary: #858585;
	--bb-color-TransparentWhiteColor: rgb(255, 255, 255, 0.8);
	--bb-color-TopLayerObjectBg: #FFFFFF;
	--bb-color-NavBarBgColor: rgb(255, 255, 255, 0.9);
	--bb-color-WhiteTextColor: #FFFFFF;
	--bb-color-PageBg: #FFFFFF;
	--bb-color-InputBg: #FFFFFF;
	--bb-color-Border: #DCDCDC;
	--bb-color-StrokeColor: #EEEEEE;
	--bb-color-LightGrayBg: #F7F7F7;
	--bb-color-ViewedBg: #FBFBFB;
	--bb-color-ButtonBg: #F6F6F6;
	--bb-color-ButtonBgHover: #f0f0f0;
	--bb-color-Gray: #CDCDCD;
	--bb-color-TooltipBg: #2b2b2b;
	--bb-color-TextColor: #202020;
	--bb-color-Accent1: #FCE64B;
	--bb-color-Accent1Light: #FFF8C3;
	--bb-color-Accent1Dark: #E5C20B;
	--bb-color-Accent1Text: #6B5A00;
	--bb-color-Accent1Hover: #F7D62C;
	--bb-color-Accent2: #561EA6;
	--bb-color-Accent2Light: #EADBFF;
	--bb-color-Accent2Text: #2D006B;
	--bb-color-Accent2Hover: #431780;
	--bb-color-Highlight1: #005915;
	--bb-color-Highlight1Bg: #EDF7F0;
	--bb-color-Highlight2: #6B3A00;
	--bb-color-Highlight2Bg: #FAF4ED;
	--bb-color-Highlight3: #00316B;
	--bb-color-Highlight3Bg: #EDF2F7;
	--bb-color-Highlight4: #6B006B;
	--bb-color-Highlight4Bg: #F7EDF7;
	--bb-color-Highlight5: #1A64BB;
	--bb-color-Success: #008844;
	--bb-color-Danger: #CB2634;
	--bb-color-DangerHover: #AD1F2B;
	--bb-color-DangerBg: #FFF2F3;
	--bb-color-Placeholder: #AFAFAF;
	--bb-color-MessageBg: #F1E7FF;
	--bb-color-Shadow5: rgba(48, 48, 48, 0.05);
	--bb-color-Shadow10: rgba(48, 48, 48, 0.1);
	--bb-color-Shadow20: rgba(48, 48, 48, 0.2);
}
